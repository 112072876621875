@import 'variables/colors';

body {
	// background-color: $black;
	// background: url("../img/background_1.jpg") no-repeat fixed center;
	// background-size: cover !important;
	// background: no-repeat fixed center;
	// background-image: url("../img/background_1.jpg");
	// background-position: 51% 88%;
	color: $white;
	font-family: 'Lato', sans-serif;
	font-weight: 200;
	padding: 0;
	margin: 0;
	// height: 100vw;
}

// additional >>

div.container--outer-wrapper {
	// background-size: cover !important;
	// background-size: contain !important;
	// background: no-repeat fixed center;
	// height: 100vh;
    // background-position-y: -122px;

	// background-image: url('../img/background_1.jpg');
}

div.background--image-wrapper {
	// background-size: cover !important;
    // background-position-y: -122px;
	// background-image: url('../img/background_2.jpg');
	// background-color: black;
	// background: url('../img/textured_logo_gold_1.jpg') no-repeat center;

	// background: url('../img/textured_logo_silver__LOGO_ONLY__muted__1.jpg') no-repeat center;
	
	background: url('../img/textured_logo_silver__LOGO_ONLY_1 __cropped.jpg') no-repeat center black;
	

    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: -10;
}
.overlay--outer-wrapper {
	background-color: black;
	// background: url('../img/textured_logo_gold_1.jpg') no-repeat center;
    height: 100vh;
    width: 100vw;
    opacity: 0.3;
    position: fixed;
}

.site-content-wrap {
	max-width: 960px;
	margin: 0 auto;
}




// << additional

.center_block {
	text-align: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.bottom_block {
	text-align: center;
	position: absolute;
	bottom: 10px;
	left: 50%;
	transform: translateX(-50%);
}

.screen {
	height: 100vh;
	position: relative;

	&.half {
		height: 50vh;
	}

	&.color1 {
		background-color: $color1;
	}

	&.color2 {
		background-color: $color2;
	}
}

// animations
.vertical_pulsate {
	animation: vertical_pulsate 3s infinite;
}

@keyframes vertical_pulsate {
	0% { transform: translate(-50%, 0px); }
	80% { transform: translate(-50%, 0px); }
	85% { transform: translate(-50%, 10px); }
	90% { transform: translate(-50%, 0px); }
	95% { transform: translate(-50%, 10px); }
	100% { transform: translate(-50%, 0px); }
}

// typography
p {
	font-size: 20px;
	margin-bottom: 10px;
	margin-top: 0px;
}

a {
	color: $white;
}

.huge {
	font-size: 75px;
	font-weight: 100;
}

// lists
ul {
	text-align: left;
}

li {
	list-style-type: none;
}








.social-links {
	ul {
		text-align: center;
		list-style-type: none;
        padding: .95rem 0 .5rem 0;

		li {
			width: 36px;
			height: 36px;
			margin: 0 3px;

			display: inline-grid;
			background-color: transparent;
			background-repeat: no-repeat;
			background-position: center center;
		}

		li.reverbnation .my_site_icon {
			background-image: url('../img/social/RN.png');
		}
		li.youtube .my_site_icon {
			background-image: url('../img/social/YT.png');
		}
		li.facebook .my_site_icon {
			background-image: url('../img/social/FB.png');
		}
		li.itunes .my_site_icon {
			background-image: url('../img/social/IT.png');
		}
		li.soundcloud .my_site_icon {
			background-image: url('../img/social/SC.png');
		}
	}
}

.subscribe {
	padding: 10px;
}

form {
	font-size: 0.9em;
	font-family: 'PT Sans',sans-serif;
	
	input {
		border: 1px solid lightgrey;
		padding: 8px;
	}
	.control {
		div {
			margin: 0.85em 0.25em .55em;
		}
	}

	.actions button {
		color: rgba(255,255,255,0.8);
		background-color: #474f5a;
		border-radius: 4px;
		padding: 0 20px;
		font-weight: normal;
		font-size: 14px;
		display: inline-block;
		text-align: center;
		cursor: pointer;
		box-sizing: border-box;
		text-transform: uppercase;
		letter-spacing: 1px;
		white-space: nowrap;
		height: 40px;
		line-height: 38px;
	}
}
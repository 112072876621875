.component-content-container--outer {
    width: 55%;
    // height: 80vh;
    margin: 0 auto;
    background: rgba(255,255,255,.84);
    color: black;
    // overflow: scroll;
    overflow: auto;

    .component-content-container--inner {
        ._embed {
            iframe {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 1280px) {
    .component-content-container--outer {
        width: 58%;
    }
}
@media screen and (max-width: 1149px) {
    .content-title {
        h1 {
            font-size: 1em !important;
        }
    }
}
@media screen and (max-width: 1024px) { // iPad
    .component-content-container--outer {
        width: 88%;
    }
}
@media screen and (max-width: 812px) { // iPhone X
    .component-content-container--outer {
        width: 88%;
    }
}
@media screen and (max-width: 736px) { // iPhone 6/7/8 Plus
    .component-content-container--outer {
        width: 100%;
    }
}
@media screen and (max-width: 575px) {
    .news-content {

        .content-title {
            h1 {
                font-size: .9em !important;
            }
            h6 {
                font-size: .7em !important;
                float: unset !important;
                display: block !important;
            }
        }
    }
}
@media screen and (max-width: 260px) {
    .content-title {
        text-align: center;
    }
}
.news-content {

    color: unset;

    a {
        color: #3A3A3A;
    }

    .content-title {
        font-family: Raleway, sans-serif;
        // color: #3A3A3A;
        padding: 15px 10px 5px;
        background: rgba(255,255,255,0.64);

        h1 {
            font-size: 1.2em;
            display: inline-block;
        }
        h6 {
            display: inline-block;
            float: right;
            font-size: .8em;
            font-style: italic;
        }
    }

    .news-detail {
        // border: 1px solid silver;
        // height: 125px;
        // padding: 12px;
        padding: 15px 10px 5px;
        box-sizing: content-box;
        font-family: "PT Sans", sans-serif;
        // color: #3A3A3A;

        // .album-cover {
        //     margin-right: 8px;
        // }

        // .album-cover, .album-cover img {
        //     height: 100%;
        // }


        p {
            font-size: .8em;

        }
        // wysiwyg fields
        p._quote_content {
            font-style: italic;
            margin-bottom: 2px;
        }
        p._quote_caption {
            margin-left: 5%;
            font-size: 0.7em;
            font-weight: bold;
        }
        div._content {
            p {
                // css here
            }
        }

        ul {
            font-size: .8em;
            padding-left: 0;
        }
        // .album-credits {
        //     vertical-align: middle;

        //     p {
        //         font-size: 0.8em;
        //         margin-bottom: 0;
        //     }
        // }

        // .album-cta {
        //     float: right;
        //     height: 100%;
        //     margin-right: 5%;

        //     .cta-buttons {
        //         // height: 100%;
        //         top: 25%;
        //         position: relative;

        //         a {
        //             display: block;
        //             font-family: "Lato";
        //             color: #3A3A3A;
        //             /* text-transform: uppercase; */
        //             font-size: 0.8em;
        //             width: 100%;
        //             text-align: center;
        //             padding: 1px 10px;
        //             margin: 5px 0;
        //             border: 1px solid silver;
        //             border-radius: 3px;
        //             text-decoration: none;
        //         }

        //         a:hover {
        //             color: white;
        //         }
        //         a.cta-buy:hover {
        //             background-color: #008CA7;
        //         }
        //         a.cta-share:hover {
        //             background-color: #4268AD;
        //         }
        //     }
        // }

    }

    // another wysiwyg field
    ._embed {
        text-align: center;
    }
}




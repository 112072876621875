div.spacer--header {
	padding: 65px 0;
}

#page-header {
    background-color: black;
	width: 100%;
}

div.header {
    height: 100px;
	z-index: -10;
}

.header-logo {
	background: url('../img/textured_logo_silver_text__black_1.jpg') no-repeat center;
	height: 100px;
	z-index: -10;
	background-size: 300px 100px;
	display: block;
	width: 300px;
    margin: 0 auto;
}

nav#main-nav.desktop {
	background-color: rgba(0,0,0,0.66);
	text-transform: uppercase;
	// font-size: 15px;
	font-size: 0.94em;
    border-top: 1px solid #444444;

	ul {
		margin: 0;
		padding: 0;
		list-style: none;
		text-align: center;
		// font-size: 15px;
		
		li {
			display: inline-block;
			width: auto;
			// width: 10%;
			height: auto;
			// margin: 0 5px;
			margin: 0 0.1em;

			a {
				display: inline-block;
				height: 100%;
				// padding-right: 1.5em;
				position: relative;
				padding: 0 1em;
				line-height: 45px;	
				white-space: nowrap;
				color: #b8b8b8;
                text-decoration: none;
                font-family: Lato, sans-serif;
                // font-family: Roboto, sans-serif;
				// font-family: Mukta, sans-serif;
				z-index: 20;
			}
			a.nav-link:hover {
				text-decoration: underline !important;
			}
			a.connect-toggle:hover {
				color: white !important;
			}
		}
	}
}

@media screen and (max-width: 823px) { // Pixel 2 XL

	.header {

		height: 86px !important;

		.header-logo {
			transform: scale(0.7);
			margin-top: -15px;
	
		}
		
	}

}

@media screen and (max-width: 667px) { // iPhone 6/7/8 

	.header {

		height: 75px !important;

		.header-logo {
			transform: scale(0.5);
			margin-top: -25px;
	
		}
	}
	nav#main-nav.desktop {
		font-size: 0.85em;
		ul {
			li {
				a {
					line-height: 30px;
				}
			}
		}
	}

}


@media screen and (max-width: 645px) {
	nav#main-nav.desktop {

		ul {
			li {
				a {
					padding: 0 0.5em;
				}
			}
		}
	}
}
@media screen and (max-width: 562px) {

}
@media screen and (max-width: 503px) {
	nav#main-nav.desktop {
		font-size: 0.8em;
	}
}
@media screen and (max-width: 451px) {
	nav#main-nav.desktop {
		font-size: .7em;

	}
}
@media screen and (max-width: 380px) {
	nav#main-nav.desktop {
		ul {
			li {
				margin: 0;
			}
		}
	}
}
@media screen and (max-width: 338px) { //iPhone 5/SE
	nav#main-nav.desktop {
		font-size: .6em;
	}
}
@media screen and (max-width: 290px) { //iPhone 5/SE
	nav#main-nav.desktop {
		font-size: .3em;
		ul {
			li {
				a {
					padding: 0 0.4em;
				}
			}
		}
	}
}
@media screen and (max-width: 240px) {
	nav#main-nav.desktop {
		ul {
			li {
				margin: 0 3em;
				a {
					line-height: 20px;
				}
			}
		}
	}
}
.yt-embed {
	// width: 94%;
	margin: 0 auto;
	iframe {
        width: 100%;
        height: 33vh;
	}
}

iframe.bandcamp-player {
    border: 0; 
    width: 100%; 
    // height: 786px;
    height: 472px;
}

.media-sidebar {
    position: absolute;
    // width: 25%;
    width: 23%;
    height: 100vh;
    top: 0;
    z-index: 10;
    // overflow: scroll;
    overflow: auto;
    opacity: .99;
    .outer {
        // display: inline-block;
        display: table-cell;
    }
    .column-feed {
        display: none;
    }
}
#media-sidebar-left, #media-sidebar-right {
    transition: width 0.5s;
}
.media-sidebar.left {
    left: 0;
    // width: 25%;
    // width: 0;
}
.media-sidebar.right {
    right: 0;
    // width: 25%;
}

.media-toggle-header {
    display: none; // adjust for RWD
    // position: absolute; // problematic when sidebar extends beyond viewport
    position: fixed;
    z-index: 30;
    top: 0; // adjust for RWD

    button {
        background-color: black;
        opacity: .8;
        color: white;
        border-top: none;
    }

    button:focus {
        outline: none;
    }
}
.media-toggle-header.video {
    left: 0;
    button {
        transform: rotate(-90deg);
        transform-origin: center bottom;
    }
}
.media-toggle-header.audio {
    right: 0;
    button {
        transform: rotate(90deg);
        transform-origin: center bottom;
    }
}

.media-toggle-column {
    vertical-align: top;
    padding-top: 75vh;

    button {
        background-color: black;
        opacity: .8;
        color: white;
        border-top: none;
    }

    button:focus {
        outline: none;
    }
}

.media-toggle-column.video button {
    transform: rotate(-90deg);
    transform-origin: center bottom;
}
.media-toggle-column.audio button {
    transform: rotate(90deg);
    transform-origin: center bottom;
}

div.column-feed {
    position: relative;
    // width: 20%;
    text-align: center;
    // border: 1px solid silver;
    // height: fit-content;
    // height: 100vh;
    // overflow: scroll;
    // top: 0;
}


div.video-feed {
    // position: absolute;
    // left: 20px;
    // left: 0;
    border-right: 1px solid #444444;
}
.column-feed-content {
    overflow: scroll;
    position: relative;
    max-height: 100vh;
    padding-bottom: 50px;
}

div.audio-feed {
    // position: absolute;
    // right: 20px;
    // right: 0;
    border-left: 1px solid #444444;
}

#video-sidebar, #audio-sidebar {
    width: unset;
}


// media sidebar RWD
// - resize height (top) to below header
// - move toggle buttons to header
// - resize width to at least 50vw

@media screen and (max-width: 1024px) { // iPad

    .media-sidebar {
        // top: 14vh; // problematic on portrait screens (vertical mobile)
        top: 102px; 
        // height: 80vh;
        z-index: 20;
    }
    .media-toggle-column {
        // padding-top: 3vh;
        // position: fixed;
        display: none !important;
        
    }

    .media-toggle-header {
        display: block;
        top: 25px;
    }

}

@media screen and (max-width: 823px) {
    .media-sidebar {
        // top: 11vh;
        top: 73px;
    }
    .media-toggle-header {
        top: 10px;
    }
}

@media screen and (max-width: 667px) {
    .media-sidebar {
        // top: 8vh;
        top: 52px;
    }
    .media-toggle-header { 
        // top: 1.5px;
        top: 0;
        font-size: 0.9em;
    }
    .media-toggle-header.video, .media-toggle-header.audio {
        button {
            transform: none;
        }
    }
    
}
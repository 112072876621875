.content-title {
    font-family: Raleway, sans-serif;
    color: #3A3A3A;
    padding: 15px 10px 5px;
    background: rgba(255,255,255,0.64);

    h1 {
        font-size: 1.2em;
    }
}

.album-detail {
    // border: 1px solid silver;
    height: 125px;
    padding: 12px;
    box-sizing: content-box;
    font-family: "PT Sans", sans-serif;
    color: #3A3A3A;

    .album-cover {
        margin-right: 8px;
    }

    .album-cover, .album-cover img {
        height: 100%;
    }

    div {
        display: inline-block;
    }

    .album-credits {
        vertical-align: middle;

        p {
            font-size: 0.8em;
            margin-bottom: 0;
        }
    }

    .album-cta {
        float: right;
        height: 100%;
        margin-right: 5%;

        .cta-buttons {
            // height: 100%;
            top: 25%;
            position: relative;

            a {
                display: block;
                font-family: "Lato";
                color: #3A3A3A;
                /* text-transform: uppercase; */
                font-size: 0.8em;
                width: 100%;
                text-align: center;
                padding: 1px 10px;
                margin: 5px 0;
                border: 1px solid silver;
                border-radius: 3px;
                text-decoration: none;
            }

            a:hover {
                color: white;
            }
            a.cta-buy:hover {
                background-color: #008CA7;
            }
            a.cta-share:hover {
                background-color: #4268AD;
            }
        }
    }

}

@media screen and (max-width: 626px) {
    .album-detail {
        height: fit-content !important;
        .album-cover {
            height: 125px !important;
        }
    }
    .album-cta {
        float: unset !important;
        display: block !important;
        .cta-buttons {
            a {
                display: inline !important;
                margin: 2px !important;
            }
        }
    }   
}
@media screen and (max-width: 575px) {
    .album-detail {
        .album-cover {
            height: 100px !important;
        }
        p.album-label {
            margin-bottom: 5px;
        }
        br {
            display: none;
        }
    }
}
@media screen and (max-width: 515px) {
    .album-detail {

        p {
            max-width: 275px;
        }

    }
}
@media screen and (max-width: 414px) { // iPhone
    .album-detail {
        p {
            max-width: 200px;
        }

        p.album-title {
            font-weight: bold;
        }

    }
}
@media screen and (max-width: 340px) {
    .album-detail {
        p.additional-credits {
            font-size: .7em;
        }
        .album-cover {
            height: 75px !important;
        }
    }
}
@media screen and (max-width: 308px) {
    .album-detail {
        p {
            max-width: 150px;
        }
        p {
            font-size: .7em !important;
        }
        .album-cover {
            height: 75px !important;
        }
    }
}
@media screen and (max-width: 260px) {

    .album-detail {
        text-align: center;
        .album-cover {
            margin-bottom: 5px;
        }
        p {
            max-width: 100%;
        }

    }
}
#home-component {

    #ccc-outer {
        background: black;
        // opacity: 1;
        // background: none;
    }

    .component-content-container--inner {
        overflow: hidden;
        position: relative;
        height: 100%;
    }
    .component-wrapper--cta {
        // display: inline-block;
        display: inline;
        // width: 100%;
   
        .connect-cta-container {
            display: block;
            margin: 0;
            height: unset;
            // width: 45%;
    
            .cta-inner {
                position: relative;
                right: 0;
                width: 100%;
                height: 100%;

                .cta-inner-inner {

                    // background: none;
                    background: black;
                    text-align: center;
                    padding-bottom: 2%;


                    div.cta-email-container {
                        a.fa-email:hover::before {
                            // color: #00AD60;
                            color: white;
                        }
                    }
                    .label-info-container {
                        display: none !important;
                    }
                    div.cta-label-container {
                        display: none;
                    }
                    .email-info-container {
                        display: none !important;
                    }
                    .cta-links-container {
                        a:before {
                            font-size: 25px;
                        }
                    }
                    div.cta-links-container, div.cta-email-container {
                        display: inline !important;
                    }

                    .cta-links-header.row {
                        display: none;
                    }
    
                    .cta-links-container.row, .cta-email-container.row {
    
                        width: 50%;
                        margin: 0 auto;
                        display: block;
                        text-align: center;
                        
                        div {
                            flex: 0 0 0;
                            max-width: unset;
                            display: inline;
                            padding: 0;
    
                            a {
                                display: inline;
                                padding: 0 5%;
                            }
                        }
                    }
                }
            }
        }
    }

    .component-wrapper--fb {
        width: 52%;
        right: 0;

        #fb-component-container {
            float: right;
        }
        
        #fb-root {
            display: inline;
        }
    }

    .gallery-photos {
        padding-top: 0;
    }


    // bootstrap carousel

    #home-carousel {
        img.carousel-img {
            width: 100%;
            height: 100vh;
            object-fit: cover;
        }

        .carousel-nav {
            // height: 100vh;
            position: absolute;
            top: 35%;
            // top: 35vh;
            // top: 25vh;
            // top: 50%;
        }
    }
}


@media screen and (max-width: 503px) {
    #home-component {
        .connect-cta-container {
            .cta-inner {
                .cta-links-container, .cta-email-container {
                    a:before {
                        font-size: 20px !important;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 451px) {
    #home-component {
        .connect-cta-container {
            .cta-inner {
                .cta-links-container, .cta-email-container {
                    a {
                        padding: 0 4% !important;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 380px) {
    #home-component {
        .connect-cta-container {
            .cta-inner {
                .cta-links-container, .cta-email-container {
                    a {
                        padding: 0 3% !important;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 240px) {
    #home-component {
        .connect-cta-container {
            .cta-inner {
                .cta-links-container, .cta-email-container {
                    a {
                        padding: 0 2% !important;
                    }
                    a:before {
                        font-size: 15px !important;
                    }
                }
            }
        }
    }
}
.reviews-content {
    color: unset;
    a {
        color: #3A3A3A;
    }
}

.reviews-content .content-title {
    font-family: Raleway, sans-serif;
    color: #3A3A3A;
    padding: 15px 10px 5px;
    background: rgba(255,255,255,0.64);
 
    .title-description {
        vertical-align: middle;
    }
    h1 {
        font-size: 1.2em;
    }
    img {
        width: 15%;
        margin: 0 8px 8px 0;
    }
    div, img {
        display: inline-block
    }
    p {
        font-style: italic;
        font-size: 0.8em; 
    }
}
.reviews-content .content-data {

    padding: 10px 20px;

    h2.review-publication {
        font-family: Raleway, sans-serif;
        color: #3A3A3A;
        font-size: 1em;
        font-style: italic;
    }

    .review-date, .review-link, .review-link a {
        font-size: .8rem;
        font-style: italic;
        color: #3A3A3A;
        margin-bottom: 2px;

    }

    div.review-content {

        margin-top: 1em;

        p {
            font-size: 0.8rem;
        }
    }


}

@media screen and (max-width: 626px) {

}
@media screen and (max-width: 575px) {

}
@media screen and (max-width: 515px) {
    .reviews-content {
        h1, h2 {
            font-size: .9em !important;
            font-weight: bold;
        }
        .content-title {
            p {
                font-size: .7em !important;
                margin-bottom: 0;
            }
        }
    }
}
@media screen and (max-width: 414px) { // iPhone
    .reviews-content {
        h1 {
            margin-bottom: 3px;
        }
        .content-title {
            text-align: center;
            img {
                display: block;
                margin: 0 auto;
                width: 100px;
                margin-bottom: 5px;
            }
        }
        .content-data {
            .review-date {
                float: left;
            }
            .review-link {
                float: right;
            }
            .review-content {
                clear: both;
                padding-top: 1em;
            }
        }
    }
}
@media screen and (max-width: 340px) {
 
}
@media screen and (max-width: 308px) {

}
@media screen and (max-width: 260px) {
    .reviews-content {

        .content-data {
            h2 {
                font-size: 0.8em !important;
            }
            .review-publication, .review-date, .review-link {
                text-align: center;
            }
            .review-date {
                float: unset;
                font-size: 0.7em !important;
            }
            .review-link {
                float: unset;
                a {
                    font-size: 0.7rem !important;
                }
            }
            .review-content {
                // clear: both;
                padding-top: unset;
            }
            
        }
    }
}
#connect-component-container {
    position: fixed;
    width: 100%;
    left: 0;
    z-index: -1;
    background: black;
    // opacity: .93;
    opacity: 0;
    transition: 0.2s opacity;
    // transition: 1s opacity ease-out;
    
    #connect--outer {
        transition: 0.5s height, 0.5s visibility;
        position: relative;
        overflow: hidden;
    }

    .connect-outer {
        width: 55%;
        margin: 0 auto;
        overflow: scroll;
        color: white;
        // background: black;
        // opacity: .93;
    }
}

.connect-cta-container, .component-wrapper--fb {
    display: inline-block;
}

.connect-cta-container {
    height: 100%;
    margin-top: 3%;
    /* width: fit-content; */
    // background: white;
    // color: black;
    // width: 40%;
    /* margin: 0 auto; */
    /* position: absolute; */
    // left: 5%;
    // top: 10%;
    // vertical-align: middle;

    .cta-inner {
        // padding: 10px 30px;
        // left: 5%;
        right: 52%;
        // background: white;
        // background: yellow; //test
        color: black;
        vertical-align: middle;
        position: absolute;
        // max-height: 100%;
        // height: 100%;
        height: 75%;
        /* height: 40px; */
        // top: 40%;
        // width: 35%;
        // width: 45%;
        width: 25%;
        // overflow-y: scroll;
        overflow-y: auto;
        overflow-x: hidden;
        box-sizing: content-box;

        .cta-inner-inner {
            background: white;
            // padding: 10px 0;
            padding: 0 0 5%;

            .email-info-container, .label-info-container {
                display: inline;
                // display: inline-block;
                // width: 50%;
                // text-align: center;
            }
            .alt-header-link {
                display: none;
            }

        }

        div.row {
            max-height: 100%;

            div {
                height: fit-content;
            }
        }

        .cta-links-header {

            // margin-top: 20px;
            // margin-bottom: 5px;
            // margin-top: 5%;
            // margin-bottom: 2%;
            padding: 5% 0 2%;

            span {
                display: inline-block;
                font-size: .8em;
                font-style: italic;
                text-align: center;
            }
        }

        .cta-links-container, .cta-email-container, .cta-label-container {
            div {
                span {
                    float: left;
                }
            }
            div:first-child {
                span {
                    float: right;
                }
            }

            a {
                color: #3A3A3A;
                display: block;
                text-align: center;
                text-decoration: none;
                // transition: 0.5s width;
                span {
                    display: none;
                    // visibility: hidden;
                    padding-top: 5px;
                    // width: 0px;
                    // overflow: hidden;
                }
            }

            a:before {
                font-family: fontello;
                font-size: 25px;
            }
            a.fa-facebook:hover::before {
                color: #4268AD;
            }
            a.fa-facebook:before {
                content: '\00FB';
            }
            a.fa-twitter:hover::before {
                color: #00A3EC;
            }
            a.fa-twitter:before {
                content: '\F304';
            }
            a.fa-youtube:hover::before {
                color: #FD0016;
            }
            a.fa-youtube:before {
                content: '\F166';
            }
            a.fa-spotify:hover::before {
                color: #00D770;
            }
            a.fa-spotify:before {
                content: '\F1BC';
            }
            a.fa-reverbnation:hover::before {
                color: #E03435;
            }
            a.fa-reverbnation:before {
                content: '\E80A';
            }
            a.fa-soundcloud:hover::before {
                color: #FF6627;
            }
            a.fa-soundcloud:before {
                content: '\F1BE';
            }
            a.fa-email:hover::before {
                // color: #00AD60;
                color: black;
            }
            a.fa-email:before {
                content: '\F0E0';
            }
            
            a.fa-label:before {
                // content: 'alfamatrix';
            }

            a.fa-label {
                background: url('../img/label/am-logo-black.png') no-repeat center;
                background-size: contain;
                height: 25px;
                margin-top: 5px;
            }
            
        }
    }
}

.component-wrapper--cta {
    display: inline;
}

.component-wrapper--fb {
    width:50%;
    position: absolute;
    right: 0;
    height: 100%;
}
#fb-component-container {
    position: relative;
    height: 100%;
    overflow: hidden;
}
.fb-embed {
    // css here
}

@media screen and (max-width: 1280px) {
    #connect-component-container .connect-outer {
        // width: 58%;
        width: 100%;
    }
}
@media screen and (max-width: 1024px) {
    .connect-cta-container {
        .cta-inner {
            width: 30%;
        }
    }
}
@media screen and (max-width: 902px) {
    .connect-cta-container {
        .cta-inner {
            width: 35%;
        }
    }
}
@media screen and (max-width: 796px) {
    .connect-cta-container {
        .cta-inner {
            width: 45%;
            .cta-links-header {
                span {
                    font-size: 0.7em;
                }
            }
        }
    }
}
@media screen and (max-width: 736px) {
    #connect-component-container .connect-outer {
        width: 100%;
    }
}
@media screen and (max-width: 575px) {
    #connect-component-container .connect-outer {
        width: 58%;
    }
    .connect-cta-container, .component-wrapper--fb {
        display: block;
        position: relative;
    }
    .connect-cta-container {
        height: fit-content;
        .cta-inner {
            right: 0;
            width: 100%;
            height: fit-content;
            position: relative;

            div.row {
                width: 100%;
                margin: 0 auto;
            }

            // .cta-links-header:first-child {
            .cta-links-header {
                span {
                    width: 50%;
                }
            }
            .email-info-container, .label-info-container {
                // display: inline;
                display: inline-block !important;
                width: 50%;
                text-align: center;
            }
            .cta-links-header.email-header, .cta-links-header.label-header {
                display: inline;
            }
            .cta-email-container .cta-email-directly, .cta-label-container .cta-label {
                // display: inline;
                a {
                    display: inline-block;
                    // width: 50%;
                    width: 100%;
                }
            }
            .cta-email-container.row.email-content, .cta-label-container.row {
                // width: 50% !important;
                // margin-left: 0 !important;
                text-align: center;
                // display: inline-block;
            }
            .cta-social-links, .cta-music-links {
                width: 50%;
                text-align: center;
                padding: 0;
                a {
                    display: unset;
                    padding: 5%;
                }
            }
        }
    }
    .component-wrapper--fb {
        width: 100%;
        right: unset;
    }
}

// addressing label header text positioning
@media screen and (max-width: 515px) {
    .connect-cta-container {
        .cta-inner {
            .cta-links-container {
                a:before {
                    font-size: 20px;
                }
            }
            .cta-links-header {
                span {
                    padding: 0;
                }
            }
        }
    }
}
@media screen and (max-width: 340px) {
    #connect-component-container .connect-outer {
        width: 100%;
    }
}
@media screen and (max-width: 308px) {
    .connect-cta-container {
        .cta-inner {
            
            .cta-links-header {
                span {
                    // font-size: 0.6em;
                }
            }
        }
    }
}
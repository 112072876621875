.lyrics-content {
    color: unset;
    a {
        color: #3A3A3A;
    }

    .content-title {
        font-family: Raleway, sans-serif;
        // color: #3A3A3A;
        padding: 15px 10px 5px;
        background: rgba(255,255,255,0.64);
        position: relative;

        h1 {
            font-size: 1.2em;
            display: inline-block;
        }
        div {
            display: inline-block;
            float: right;
            position: absolute;
            top: 5px;
            right: 10px;
        }
        h6 {
            // display: inline-block;
            // float: right;
            font-size: .8em;
            font-style: italic;
        }
    }

    .lyrics-detail {
        // border: 1px solid silver;
        // height: 125px;
        // padding: 12px;
        padding: 10px 10px 5px;
        box-sizing: content-box;
        font-family: "PT Sans", sans-serif;
        // color: #3A3A3A;

        .lyrics-song {

            margin-bottom: 10px;

            h6 {
                font-style: italic;
                text-decoration: underline;
                font-size: 0.9em;
                font-weight: bold;
            }
        }
        p {
            font-size: .8em;

        }
        // wysiwyg fields
        p._quote_content {
            font-style: italic;
            margin-bottom: 2px;
        }
        p._quote_caption {
            margin-left: 5%;
            font-size: 0.7em;
            font-weight: bold;
        }
        // div._content {
        //     // p {
        //     //     // css here
        //     // }
        // }

        ul {
            font-size: .8em;
            padding-left: 0;
        }

    }

    // another wysiwyg field
    ._embed {
        text-align: center;
    }

    // dev note: JUST realized that I can place media queries inside of a css selector (at least with sass)
    @media screen and (max-width: 414px) {
        .content-title {
            div {
                display: block;
                float: unset;
                position: unset;
                font-size: .9em;
                h6 {
                    margin-bottom: 5px;
                }
                
            }
        }
    
    }
} // / .lyrics-content

